'use client'

import { Alert, Box, Button, Link, Typography } from '@mui/material'
import * as Sentry from '@sentry/nextjs'
import NextError from 'next/error'
import { useEffect } from 'react'

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <Box p={2}>
      <Alert severity="error">
        <Typography variant="h6">Sivustolla ongelmia</Typography>
        <Typography>{`Virhe: ${error.message}`}</Typography>
      </Alert>
      <Typography sx={{ mt: 1 }} variant="body1">
        Yritä ladata sivu uudelleen.
      </Typography>
      <Typography sx={{ mt: 3 }} variant="body1">
        Jos ongelma toistuu, raportoi virhe.
      </Typography>
      <Box>
        <Link href="/info">
          <Button sx={{ mt: 2 }}>Raportoi virhe</Button>
        </Link>
      </Box>
    </Box>
  )
}
